import { makeStyles } from '@material-ui/core/styles'
import { theme as localTheme } from 'styles'

export const buttonUseStyles = makeStyles(theme => ({
  primary: {
    color: localTheme.colors.primarygreen,
    border: `3px solid ${localTheme.colors.primarygreen}`,
    backgroundColor: 'transparent',
    '&:hover': {
      color: localTheme.colors.white,
      backgroundColor: localTheme.colors.primarygreen,
    },
    '&:disabled': {
      opacity: 0.7,
      color: localTheme.colors.green70,
      backgroundColor: localTheme.colors.green40,
      cursor: 'not-allowed',
      border: `3px solid ${localTheme.colors.primarygreen}`,
    },
  },
  secondary: {
    transitionProperty: 'border color',
    border: `1px solid ${localTheme.colors.primarygreen}`,
    color: localTheme.colors.primarygreen,
    backgroundColor: 'none',
    '&:hover': {
      color: 'none',
      border: `1px solid ${localTheme.colors.primarygreen}`,
      backgroundColor: localTheme.colors.primarygreen,
      '& .DotLoader': {
        '& > div > div': {
          backgroundColor: localTheme.colors.primary,
        },
      },
    },
    '&:disabled': {
      opacity: 0.7,
      color: localTheme.colors.secondary,
      backgroundColor: localTheme.colors.tertiary,
      cursor: 'not-allowed',
    },
    '& .DotLoader': {
      '& > div > div': {
        backgroundColor: localTheme.colors.secondary,
      },
    },
  },
  root: {
    textTransform: 'uppercase',
    display: 'inline-flex',
    borderRadius: '0px',
    paddingTop: '8px',
    paddingBottom: '10px',
    minWidth: 130,
    width: 'auto',
    letterSpacing: '1.14px',
    position: 'relative',
    textAlign: 'center',
    textDecoration: 'none',
    fontSize: localTheme.fonts.m,
    fontFamily: localTheme.fontFamily.primary,
    fontWeight: 500,
    '&:focus': {
      outline: 'none',
    },
    '&:disabled': {
      opacity: 0.7,
      cursor: 'not-allowed',
    },
    '& .DotLoader': {
      marginLeft: '5px',
    },
  },
}))
