import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { Button as ButtonMaterial } from '@material-ui/core'
import { buttonUseStyles } from './styles'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import LocaleContext from 'context/LocaleProvider'

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      label: {},
    },
  },
})

const Button = ({
  children,
  buttonStyle = 'primary',
  className,
  to,
  href,
  style,
  type,
  disabled,
  clickHandler = () => {},
}) => {
  const lang = useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  const classes = buttonUseStyles()
  const buttonProps = {
    children,
    type: type === 'submit' ? 'submit' : 'button',
    style: style ? style : null,
    className: `Button ${classes.root} ${className ? className : ''} ${
      classes[buttonStyle]
    }`,
  }

  const localizedTo = i18n.locale === 'en-gb' ? `/en/${to}` : `/${to}`

  return (
    <ThemeProvider theme={theme}>
      <ButtonMaterial
        disabled={disabled}
        role="button"
        {...buttonProps}
        onClick={to ? () => navigate(to ? localizedTo : '/') : clickHandler}
        href={href ? href : ''}
      >
        {children}
      </ButtonMaterial>
    </ThemeProvider>
  )
}

Button.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  to: PropTypes.string,
  buttonStyle: PropTypes.oneOf(['primary', 'secondary']),
  style: PropTypes.object,
  href: PropTypes.string,
  type: PropTypes.string,
  clickHandler: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
}

export default Button
